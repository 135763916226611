<template>
    <div id="Container">

        <div id="blog_title" class="blog_title">
            <div class="blog_title_contenedor borderblue">
                <div class="blog_title_titular">Bienvenido al blog de entre tanto cuento</div>
                <div class="blog_title_parrafo">En este espacio podrás actualizarte respecto a las novedades del portal: nuevos vídeos, entrevistas, actualizaciones en el portal, eventos y mucho más.</div>
            </div>
        </div>

        <div id="modulos">
            <div v-for="b in blogs" :key="b" class="modulo" data-aos="zoom-in" @click="openvideo(b)">
                <div class="modulo_contenedor borderblue">
                    <img :src="require('../assets/website/blog/'+b.imagen_blog+'.png')" alt="Fórmate" class="modulo_logo">
                    <div class="modulo_titular">{{ b.titular }}</div>
                    <div class="modulo_parrafo">{{ b.descripcion_breve }}</div>
                    <div class="modulo_vermas">Ver más +</div>
                </div>
            </div>
        </div>

        <div id="blog_detail" class="blog_detail">
            <div class="blog_volver"  @click="closevideo">&lt; Volver</div>
            <div class="modulo_contenedor borderblue">
                <img id="blog_detail_imagen" :src="require('../assets/website/blog/Ancho_Autentico.png')" alt="Fórmate" class="modulo_logo">
                <div id="blog_detail_titular" class="modulo_titular">{{ titular }}</div>
                <div id="blog_detail_parrafo" class="modulo_parrafo" v-html="parrafo"></div>

                <div id="blog_detail_video">
                    <iframe id="blog_detail_videoframe" width="560" height="315" src="https://www.youtube.com/embed/ddoUVP56gsU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import useBlog from '../store/contenidoBlog'

export default {
  setup () {
    const entradasBlog = useBlog()
    return {
      blogs: entradasBlog.noticias
    }
  },
  data () {
    return {
      titular: '',
      parrafo: '',
      imagen: ''
    }
  },
  methods: {
    openvideo: function (blog) {
      const blogtitle = document.querySelector('#blog_title')
      const modulos = document.querySelector('#modulos')
      const blogdetail = document.querySelector('#blog_detail')

      const imagen = document.querySelector('#blog_detail_imagen')

      const video = document.querySelector('#blog_detail_video')
      const videoframe = document.querySelector('#blog_detail_videoframe')

      this.titular = blog.titular
      this.parrafo = blog.texto
      imagen.src = require('../assets/website/blog/' + blog.imagen_encabezado + '.png')
      videoframe.src = blog.video

      blogtitle.style.display = 'none'
      modulos.style.display = 'none'
      blogdetail.style.display = 'block'

      if (blog.video) {
        video.style.display = 'block'
      } else {
        video.style.display = 'none'
      }
    },
    closevideo: function () {
      const blogtitle = document.querySelector('#blog_title')
      const modulos = document.querySelector('#modulos')
      const blogdetail = document.querySelector('#blog_detail')
      blogtitle.style.display = 'block'
      modulos.style.display = 'flex'
      blogdetail.style.display = 'none'
    }
  }
}
</script>

<style scoped>
#Container {
    background-color: #54ceea;
    background-image: url('../assets/patron_home.svg');
    background-attachment: fixed;
    margin-bottom: -200px;
    padding-top: 50px;
}

#video-view {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.overlay {
    background: rgba(51,51,51,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999999;

    display: flex;
    align-items: center;
    justify-content: center;
}
.class-video {
    width: 70%;
}
@media screen and (max-width: 820px) {
    .class-video {
        width: 90%;
    }
}
video {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

#video {
    background-image: url('../assets/website/home/imagen_manifestacion.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px;
    height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
#video_title {
    color: white;
    margin: 0px auto;
    font: 50px "Bebas Neue";
    padding: 20px;
}
#video_parrafo {
    color: white;
    width: 70%;
    max-width: 800px;
    margin: 0px auto;
    font: 20px "Helvetica";
    padding: 20px;
    text-align: justify;
}
#video_play {
    margin-top: 20px;
    width: 60px;
}
#video_play:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#titulo_modulos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#titulo_modulos_cuerdas {
    width: 200px;
    height: 75px;
    border-style: none solid;
    border-width: 3px;
    border-color: white;
}
#titulo_modulos_titulo {
    width: 300px;
    height: 75px;
    background-color: #0C1D3E;
    color: #54cde9;
    font: 32px "Bebas Neue";
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.borderwhite,
.borderwhite:before {
  border-radius: 15px;
  box-sizing: border-box;
}

.borderwhite:before {
  border: 3px solid white;
  content: "";
  height: 100%;
  width: 100%;

  position: absolute;
  top: -5px;
  left: 5px;
  z-index: 0;
}

.borderblue,
.borderblue:before {
  border-radius: 10px;
  box-sizing: border-box;
}
.borderblue:before {
  border: 3px solid #0C1D3E;
  content: "";
  height: 100%;
  width: 100%;

  position: absolute;
  top: var(--mouse-y, -6px);
  left: var(--mouse-x, 6px);
  z-index: 0;
}

#blog_detail_parrafo {
    z-index: 99;
}
.enlace {
    color: red !important;
}
#blog_detail_video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-top: 30px;
}
#blog_detail_video iframe {
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#modulos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;

    padding: 40px 0px 240px;
    max-width: 1000px;
    margin: 0px auto;
}
.modulo {
    width: 20vw;
    min-width: 300px;
    margin: 20px;
}
.blog_title {
    width: 40vw;
    min-width: 300px;
    margin: 0 auto;
}
.blog_volver {
    color: white;
    background: #0C1D3E;
    font: 32px "Bebas Neue";
    width: 150px;
    border-radius: 15px 15px 0 0;
    padding: 5px 0 3px;
    margin-left: 30px;
    margin-bottom: 10px;
}
.blog_volver:hover {
    cursor: pointer;
}
.blog_detail {
    width: 40vw;
    min-width: 300px;
    margin: 0 auto;
    padding-bottom: 240px;
    display: none;
}
.blog_title_contenedor {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    top: var(--mouse-y, -6px);
    left: var(--mouse-x, 6px);
}
.modulo_contenedor {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 30px;
    position: relative;
    top: var(--mouse-y, -6px);
    left: var(--mouse-x, 6px);
}
.modulo_logo {
    width: 90%;
    margin: 0 auto 20px;
}
.modulo_titular {
    text-align: left;
    color: #54ceea;
    font: 28px "Bebas Neue";
}
.modulo_parrafo {
    text-align: left;
}
.blog_title_titular {
    color: #54ceea;
    font: 28px "Bebas Neue";
    width: 30%;
}
.blog_title_parrafo {
    text-align: left;
    width: 70%;
    margin-top: 15px;
}
.modulo_vermas {
    text-align: left;
    margin: 20px 0 -10px;
    color: #66e887;
}
.modulo_boton {
    background-color: #0C1D3E;
    color: white;
    font: 32px "Bebas Neue";
    border-radius: 10px;
    width: 40%;
    margin: 20px auto 0px;
    line-height: 1;
    padding: 4px 0px 0px;
}
.modulo_boton:hover {
    color: #54ceea;
    transform: scale(1.1);
}

@media screen and (max-width: 820px) {
    #video {
        padding: 30px 10px;
        height: 70vh;
    }
    #video_title {
        font: 30px "Bebas Neue";
        padding: 20px;
    }
    #video_parrafo {
        width: 80%;
        font: 16px "Helvetica";
    }
    .blog_title_titular {
        width: 90%;
    }
    .blog_title_parrafo {
        width: 90%;
    }
}
</style>
