<template>
    <div id="Container">
        <div id="video">
            <div id="video_title">Bienvenido a Entre Tanto Cuento</div>
            <div id="video_parrafo">
                En “Detectores de Noticias Falsas” encontrarás un espacio con diferentes herramientas y estrategias que hemos diseñado para ayudarte a identificar qué son las noticias falsas, porqué debemos tener cuidado con este fenómeno y cómo puedes ayudar a detenerlo mediante la identificación de tus propios sesgos cognitivos.
            </div>
            <img id="video_play" @click="openvideo" src="../assets/website/home/boton_play.svg" alt="Reproducir Video">
        </div>

        <div id="titulo_modulos">
            <div id="titulo_modulos_cuerdas"></div>
            <div id="titulo_modulos_titulo" class="borderwhite">Modulos de la Campaña</div>
        </div>

        <div id="modulos">
            <div class="modulo" data-aos="zoom-in">
                <router-link to="/formate">
                    <div class="modulo_contenedor borderblue">
                        <img src="../assets/website/global/logo_formate.svg" alt="Fórmate" class="modulo_logo">
                        <div class="modulo_parrafo">
                            Entra y descubre qué y cuáles son los sesgos cognitivos en los que puedes caer al consumir noticias en internet y cómo descubrir si estás visitando un portal de información falsa.
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="modulo" data-aos="zoom-in">
                <router-link to="/pruebate">
                    <div class="modulo_contenedor borderblue">
                        <img src="../assets/website/global/logo_pruebate.svg" alt="Pruébate" class="modulo_logo">
                        <div class="modulo_parrafo">
                            ¿Tienes las habilidades para diferenciar información verdadera de información falsa en internet? Pruébate con esta experiencia de juego y demuestra tus conocimientos.
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="modulo" data-aos="zoom-in">
                <router-link to="/difunde">
                    <div class="modulo_contenedor borderblue">
                        <img src="../assets/website/global/logo_difunde.svg" alt="Difunde" class="modulo_logo">
                        <div class="modulo_parrafo">
                            Si eres un profesor o adulto en busca de información para llevar esta experiencia a los más jóvenes, aquí encontrarás recursos para el desarrollo de experiencias pedagógicas.
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="modulo" data-aos="zoom-in">
                <router-link to="/comparte">
                    <div class="modulo_contenedor borderblue">
                        <img src="../assets/website/global/logo_comparte.svg" alt="Comparte" class="modulo_logo">
                        <div class="modulo_parrafo">
                            Detener el consumo y difusión de las noticias falsas es una tarea en la que todos podemos aportar. Ingresa y descubre cómo puedes ayudar a detener este fenómeno.
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div id="video-view">
            <div class="overlay" @click="closevideo">
                <div class="class-video">
                    <video id="videohome">
                        <source src="https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/video_entretantocuento.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  mounted () {
    /* document.addEventListener('mousemove', evt => {
      const x = -24 * ((evt.clientX / innerWidth) - 0.5)
      const y = 24 * ((evt.clientY / innerHeight) - 0.5)
      document.documentElement.style.setProperty('--mouse-x', x + 'px')
      document.documentElement.style.setProperty('--mouse-y', y + 'px')
    }) */
  },
  methods: {
    openvideo: function () {
      const videoview = document.querySelector('#video-view')
      const videohome = document.querySelector('#videohome')
      videoview.style.display = 'block'
      videohome.play()
    },
    closevideo: function () {
      const videoview = document.querySelector('#video-view')
      const videohome = document.querySelector('#videohome')
      videoview.style.display = 'none'
      videohome.pause()
      videohome.currentTime = 0
    }
  }
}
</script>

<style scoped>
#Container {
    background-color: #54ceea;
    background-image: url('../assets/patron_home.svg');
    background-attachment: fixed;
    margin-bottom: -200px;
}

#video-view {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.overlay {
    background: rgba(51,51,51,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999999;

    display: flex;
    align-items: center;
    justify-content: center;
}
.class-video {
    width: 70%;
}
@media screen and (max-width: 820px) {
    .class-video {
        width: 90%;
    }
}
video {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

#video {
    background-image: url('../assets/website/home/imagen_manifestacion.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px;
    height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
#video_title {
    color: white;
    margin: 0px auto;
    font: 50px "Bebas Neue";
    padding: 20px;
}
#video_parrafo {
    color: white;
    width: 70%;
    max-width: 800px;
    margin: 0px auto;
    font: 20px "Helvetica";
    padding: 20px;
    text-align: justify;
}
#video_play {
    margin-top: 20px;
    width: 60px;
}
#video_play:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#titulo_modulos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#titulo_modulos_cuerdas {
    width: 200px;
    height: 75px;
    border-style: none solid;
    border-width: 3px;
    border-color: white;
}
#titulo_modulos_titulo {
    width: 300px;
    height: 75px;
    background-color: #0C1D3E;
    color: #54cde9;
    font: 32px "Bebas Neue";
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.borderwhite,
.borderwhite:before {
  border-radius: 15px;
  box-sizing: border-box;
}

.borderwhite:before {
  border: 3px solid white;
  content: "";
  height: 100%;
  width: 100%;

  position: absolute;
  top: -5px;
  left: 5px;
  z-index: 0;
}

.borderblue,
.borderblue:before {
  border-radius: 10px;
  box-sizing: border-box;
}
.borderblue:before {
  border: 3px solid #0C1D3E;
  content: "";
  height: 100%;
  width: 100%;

  position: absolute;
  top: var(--mouse-y, -6px);
  left: var(--mouse-x, 6px);
  z-index: 0;
}

#modulos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;

    padding: 40px 0px 240px;
    max-width: 1000px;
    margin: 0px auto;
}
.modulo {
    width: 20vw;
    min-width: 300px;
    margin: 20px;
}
.modulo_contenedor {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    top: var(--mouse-y, -6px);
    left: var(--mouse-x, 6px);
}
.modulo_contenedor:hover {
    cursor: pointer;
    background-color: #66e887;
}
.modulo_logo {
    width: 60%;
    margin-bottom: 20px;
}
.modulo_parrafo {
    text-justify: inter-word;
}
.modulo_boton {
    background-color: #0C1D3E;
    color: white;
    font: 32px "Bebas Neue";
    border-radius: 10px;
    width: 40%;
    margin: 20px auto 0px;
    line-height: 1;
    padding: 4px 0px 0px;
}
.modulo_boton:hover {
    color: #54ceea;
    transform: scale(1.1);
}

@media screen and (max-width: 820px) {
    #video {
        padding: 30px 10px;
        height: 70vh;
    }
    #video_title {
        font: 30px "Bebas Neue";
        padding: 20px;
    }
    #video_parrafo {
        width: 80%;
        font: 16px "Helvetica";
    }
}
</style>
